// @flow

import React, { Component } from 'react'
import { Breakpoint } from 'react-socks'
import { Container, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Lottie from 'react-lottie'
import differenceInHours from 'date-fns/differenceInHours';
import * as animationData from '../assets/loading.json'
import * as animationData404 from '../assets/404.json'
import '../styles/RestaurantTable.scss'
import { updateRestoInfo, updateType, resetCart, applyDiscount, applyCoupon, pickedOrderType, updateTable, updateQrId } from '../actions/cartAction';
import { updateRestoSettings, updateDeliverySchedule, updateOrderSchedule, updateDeliverySettings, updateTakeawaySettings } from '../actions/settingsAction';

import CartTable from './CartTable'
import ProductDetail from './ProductDetail'
import $ from "jquery"

import { parseSchedule, computeDiscount, convertFirebaseUrlToCustomDomain } from './Utils'
import { CartType, ProductType } from '../flowObjectType'
import { firestore, analytics } from '../services/firebase'

// color
// text black
// background #F1F1F1
// blue #00CCBB
// yellow #FDC151
// red #FF4B4B

type Props = {
  match: Object,
  cart: CartType,
  settings: Object,
  updateType: Function,
  updateRestoInfo: Function,
  updateRestoSettings: Function,
  updateDeliverySchedule: Function,
  updateOrderSchedule: Function,
  resetCart: Function,
  updateTakeawaySettings: Function,
  updateDeliverySettings: Function,
}

type State = {
  canOrder: boolean,
  settings: Object,
  informations: Object,
  showProductDetail: boolean,
  restaurant: Object,
  sections: Array<Object>,
  selectedProduct: ?ProductType,
  invalidQrCode: boolean,
  selectedCategoryIdx: number,
  selectedIdx: ?number,
  orderType: ?number,
  table: ?string,
  showCart: boolean
}

function goToAnchor(event: Object, id: string) {
  const productPositionY = $(`#section-${id}`).offset().top - 65
  // $(event.target).css('backgroundColor', 'white')
  $("html, body").animate({ scrollTop: productPositionY }, 500);
  event.preventDefault();
}

let savedSticky = 0
function handleStickyHeader(header) {
  let sticky = header[0].offsetTop
  if (header.hasClass("sticky")) {
    sticky = savedSticky
  }

  if (window.pageYOffset > sticky) {
    savedSticky = sticky
    header.addClass("sticky");
  } else {
    header.removeClass("sticky");
  }
}
function handleActiveSection(header) {

  const topMenuHeight = header.outerHeight() + 10
  const menuItems = header.find(".options-btn")
  const scrollItems = menuItems.map(function () {
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
    return null
  });

  var fromTop = window.scrollY + topMenuHeight;
  var lastId;
  // Get id of current scroll item

  var cur = scrollItems.map(function () {
    if ($(this).offset().top < fromTop)
      return this;
    return null
  });
  // Get the id of the current element
  cur = cur[cur.length - 1];
  var id = cur && cur.length ? cur[0].id : "";

  if (lastId !== id) {
    lastId = id;
    // Set/remove active class
    if (id === "") return
    menuItems.removeClass("selected")
    const selectedItem = menuItems.filter("[href='#" + id + "']")
    selectedItem.addClass("selected");
    if (selectedItem[0]) {
      selectedItem[0].scrollIntoView({
        behavior: 'instant',
      });
    }
  }

}

const CategoryRow = (props) => props.category.displayApp === false ?
  (<></>)
  : (
    <>
      <a href={`#section-${props.category.id}`} className={`options-btn ${props.selected ? 'selected' : ''}`} onClick={(event) => { goToAnchor(event, props.category.id) }}>
        {props.category.name}
      </a>
    </>
  )

const Section = (props) => (
  <div>
    <h3 className="section" id={`section-${props.id}`}>
      <div className="section-title">{props.title.toUpperCase()}</div>
    </h3>
    {props.description && (
      <div className="section-description">
        {props.description}
      </div>
    )}
  </div>
)

const ProductRow = (props) => {
  const { product, orderType } = props
  const cartProducts = props.cartProducts ? props.cartProducts.filter(e => e.id === product.id) : [];
  const totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;
  const price = `${product.unitPrice.toFixed(2)} €`
  const unitPrice = parseFloat(product.unitPrice);

  let discountPrice = ``;
  let hasDiscount = false;
  let discountLabel = '';

  if (orderType === 1 && product.takeawayDiscount && product.takeawayDiscount.discount) {
    discountPrice = unitPrice * (1 - product.takeawayDiscount.discount / 100)
    hasDiscount = true;
    discountLabel = `${product.takeawayDiscount.discount}% `
  }
  else if (orderType === 4 && product.deliveryDiscount && product.deliveryDiscount.discount) {
    discountPrice = unitPrice * (1 - product.deliveryDiscount.discount / 100)
    hasDiscount = true;
    discountLabel = `${product.deliveryDiscount.discount}% `
  }
  else if ((orderType === 2 || orderType === 3) && product.tableDiscount && product.tableDiscount.discount) {
    discountPrice = unitPrice * (1 - product.tableDiscount.discount / 100)
    hasDiscount = true;
    discountLabel = `${product.tableDiscount.discount}% `
  }

  return (
    <div className={`product-box ${cartProducts.length > 0 ? 'selected' : ''}`} onClick={props.toggleModal(product)}>
      <div className="product-text-container">
        <div className="product-box-name">
          <div className="product-name">{product.name}</div>
        </div>
        {product.description !== "" && (
          <div className="product-description">
            <p>
              {product.description}
            </p>
          </div>
        )}

        {hasDiscount && (
          <div className={`product-price ${!product.canOrder ? 'product-not-available' : ''}`} style={{ alignItems: 'center' }}>
            <span style={{ textDecoration: 'line-through' }}>{unitPrice.toFixed(2)} €</span>
            <span>{`    -${discountLabel} soit ${discountPrice.toFixed(2)} €`}</span>
            {!product.canOrder && (`indisponible`)}
          </div>
        )}
        {!hasDiscount && product.unitPrice > 0 && (
          <div className={`product-price ${!product.canOrder ? 'product-not-available' : ''}`}>
            {price}
            {!product.canOrder && (`indisponible`)}
          </div>
        )}
      </div>
      {product.image.path !== '' && (
        <div className="image-container">
          <img
            className="img"
            src={convertFirebaseUrlToCustomDomain(product.image.path)}
            alt=""
          />
        </div>
      )}
      {cartProducts.length > 0 && (
        <div className="product-box-quantity">
          {totalQuantity}
        </div>
      )}
    </div>
  )
}

class RestaurantTable extends Component<Props, State> {
  unsubscribe: Object
  refRestaurant: Object
  refProduct: Object
  refCategory: Object
  refDiscount: Object
  refOption: Object
  readOnly: boolean
  products: Array<Object>
  categories: Array<Object>
  discounts: Array<Object>
  options: Array<Object>
  selectCategory: Function

  constructor(props: Object) {
    super(props);

    this.unsubscribe = null
    this.refRestaurant = null
    this.refProduct = null
    this.refCategory = null
    this.refDiscount = null
    this.refOption = null

    this.readOnly = false
    this.products = []
    this.categories = []
    this.discounts = []
    this.options = []

    this.state = {
      canOrder: false,
      settings: null,
      informations: null,
      showProductDetail: false,
      restaurant: null,
      sections: [],
      selectedProduct: null,
      invalidQrCode: false,
      selectedCategoryIdx: 0,
      selectedIdx: undefined,
      orderType: undefined,
      table: null,
      showCart: false,
    }
  }

  componentDidMount() {
    const cartCreatedAt = new Date(this.props.cart.createdAt);
    if (this.props.cart.flushCart === true || isNaN(cartCreatedAt) || differenceInHours(new Date(), cartCreatedAt) >= 10)
      this.props.resetCart();
    const id = this.props.match.params.id

    if (id && id.length === 41) {
      this.handleTableQr(id)
    }
  }

  handleTableQr = (barcode) => {
    const restoId = barcode.split('-')[0]
    const tableId = barcode.split('-')[1]
    const ref = firestore().collection(`Restaurant/${restoId}/QRCode`).doc(tableId)
    ref.get()
      .then(doc => {
        if (!doc.exists) {
          setTimeout(() => {
            this.setState({ invalidQrCode: true })
          }, 200)
          return false
        } else {
          const qrcode = doc.data()
          this.initRestaurant(restoId)
          this.props.updateType(3)
          this.props.updateTable(qrcode.table)
          this.props.updateQrId(doc.id)
          this.setState({
            table: qrcode.table,
            orderType: 3
          })
          return true
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({ invalidQrCode: true })
      })
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe()
  }

  loadScript = () => {
    const header = $("#sticky-header");
    if (header.length) {
      window.onscroll = () => {
        handleActiveSection(header)
        handleStickyHeader(header)
      }
    }
  }

  initRestaurant = (restoId) => {
    this.refRestaurant = firestore().collection('Restaurant').doc(restoId)
    this.refProduct = firestore().collection(`Restaurant/${restoId}/Product`)
    this.refOption = firestore().collection(`Restaurant/${restoId}/Rubric`)
    this.refCategory = firestore().collection(`Restaurant/${restoId}/Category`).where('status', '==', 1)
    this.refDiscount = firestore().collection(`Restaurant/${restoId}/Discount`)
    this.refRecommendation = firestore().collection(`Restaurant/${restoId}/Recommendation`)

    this.unsubscribe = this.refRestaurant.onSnapshot(this.onRestoUpdate)
    this.unsubscribeProduct = this.refProduct.onSnapshot(this.onProductUpdate)
    this.unsubscribeCategory = this.refCategory.onSnapshot(this.onCategoryUpdate)

    const productP = this.refProduct.get()
    const categoryP = this.refCategory.get()
    const discountP = this.refDiscount.get()
    const optionP = this.refOption.get()
    const recoP = this.refRecommendation.get()

    Promise.all([productP, categoryP, discountP, optionP])
      .then(([product, category, discount, option]) => {
        const products = []
        const categories = []
        const discounts = []
        const options = []
        const recos = []

        product.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          if ((data.display?.eatin === true) || (data.display?.eatin === undefined && (data.displayApp || data.displayApp === undefined))) {
            products.push({ id: doc.id, ...data })
          }
        });
        category.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          if (!data.internal && (data.displayApp || data.displayApp === undefined)) categories.push({ id: doc.id, ...data })
        });
        discount.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          discounts.push({ id: doc.id, ...data })
        });
        option.forEach((doc) => {
          const { id, products, shared, ...data } = doc.data()
          options.push({ id: doc.id, ...data })
        })
        this.products = products
        this.categories = categories.sort((a, b) => (a.index < b.index ? -1 : 1))
        this.discounts = discounts
        this.options = options
        this.recommendations = recos
        this.getSections()
        return true
      })
      .catch(err => {
        console.log(err)
        return false
      })
  }

  onRestoUpdate = (querySnapshot: Object) => {
    const restaurant = querySnapshot.data()
    const orderSchedule = parseSchedule(restaurant.settings.orderSchedule);
    const deliverySchedule = parseSchedule(restaurant.settings.deliverySchedule);

    const deliverySettings = restaurant.deliverySettings || {};
    const takeawaySettings = restaurant.takeawaySettings || {};

    this.props.updateRestoInfo(restaurant.informations.id, restaurant.informations.name, restaurant.informations.postalCode, restaurant.state.canOrderQr ?? true, restaurant.informations);
    this.props.updateRestoSettings(restaurant.informations.id, restaurant.settings.color, restaurant.settings.autoAcceptOrder, restaurant.settings.delivery, restaurant.settings.takeaway, restaurant.settings.table, restaurant.settings.stripe || '', restaurant.settings.orderTypePopup || false);
    this.props.updateDeliverySchedule(deliverySchedule);
    this.props.updateOrderSchedule(orderSchedule);
    this.props.updateDeliverySettings(deliverySettings);
    this.props.updateTakeawaySettings(takeawaySettings);

    if (!restaurant.settings.delivery && !restaurant.settings.takeaway && !restaurant.settings.table) {
      this.readOnly = true
    }


    analytics().logEvent('home_page', { restoId: restaurant.informations.id, restoName: restaurant.informations.name });


    setTimeout(() => {
      this.setState({
        settings: restaurant.settings,
        informations: restaurant.informations,
        restaurant: restaurant,
        canOrder: restaurant.state.canOrderQr ?? true
      })
      this.loadScript()
    }, 1000)
  }

  onProductUpdate = (querySnapshot: Object) => {
    const products = []

    querySnapshot.forEach((doc) => {
      const { createdAt, ...data } = doc.data()

      if ((data.display?.eatin === true) || (data.display?.eatin === undefined && (data.displayApp || data.displayApp === undefined))) {
        products.push({ id: doc.id, ...data });
      }

    });
    this.products = products
    this.getSections()
  }

  onCategoryUpdate = (querySnapshot: Object) => {
    const categories = []
    querySnapshot.forEach((doc) => {
      const { createdAt, ...data } = doc.data()
      if (!data.internal && (data.displayApp || data.displayApp === undefined)) categories.push({ id: doc.id, ...data })
    })
    this.categories = categories.sort((a, b) => (a.index < b.index ? -1 : 1))
    this.getSections()
  }


  toggleModal = (product) => () => {
    if (product.canOrder)
      this.setState(prevState => ({
        selectedProduct: { ...product },
        showProductDetail: !prevState.showProductDetail,
        selectedIdx: undefined
      }))
  }

  closeProductModal = () => {

    this.setState({ showProductDetail: false })
  }

  getSections = () => {
    const sections = []
    for (let i = 0; i < this.categories.length; i++) {
      const products = this.products.filter((item) => item.categoryId === this.categories[i].id).sort(((a, b) => (a.index < b.index ? -1 : 1)))
      if (products.length === 0) {
        this.categories[i].displayApp = false
        continue
      }
      sections.push({
        title: this.categories[i].name,
        description: this.categories[i].description,
        categoryMaxQuantity: this.categories[i].maxQuantity || 0,
        data: products,
        index: i,
        id: this.categories[i].id
      })
    }
    this.setState({ sections })
  }


  onClickCartProduct = (index) => e => {
    this.setState({
      selectedProduct: this.props.cart.products[index],
      showProductDetail: true,
      selectedIdx: index

    });
  };

  getOrderTotal = () => {
    let total = this.props.cart.total;
    if (this.props.cart.coupon !== null) {
      if (this.props.cart.coupon?.discountType === 'percent') {
        total = Number.parseFloat(computeDiscount(total, this.props.cart.coupon.discountPercent, true).toFixed(2));
      }
      else if (this.props.cart.coupon?.discountType === 'price') {
        total -= this.props.cart.coupon.discountPrice;
      }
    }
    return total;
  }

  closeCartModal = () => {
    this.setState({ showCart: false })
  }

  render() {
    if (this.state.invalidQrCode) {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData404.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }
      return (
        <div id="not-found">
          <Breakpoint medium up>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
        </div>
      )
    }
    if (!this.state.informations) {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }

      return (
        <div className="center-loading">
          <Breakpoint medium up>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
        </div>
      )
    }

    return (
      <Container className="container" id="main-table">
        {
          this.props.settings.color && (
            <style> {`body {
              --website-color: ${this.props.settings.color}
            }`}
            </style>
          )
        }
        <div className="header">

          <div className="restaurant-info">
            <div className="restaurant-name">
              {this.state.informations.name}
            </div>

            <div className="restaurant-info-table">
              <em>Vous êtes à la table <b>{this.state.table}</b></em>
            </div>
          </div>
        </div>

        <div className="category-container" id="sticky-header">

          <div className="options">
            {this.categories.map((category, idx) =>
              <CategoryRow
                category={category}
                key={category.id}
                idx={idx}
                selected={idx === this.state.selectedCategoryIdx}
                selectCategory={this.selectCategory}
              />
            )}
          </div>
        </div>

        <Breakpoint medium up>
          <div className="product-grid">
            <div className="product-container">
              {this.state.sections.map((section, index) => (
                <div key={index}>
                  <Section title={section.title} id={section.id} description={section.description} />
                  <div className="product">
                    {section.data.map((product) =>
                      <ProductRow
                        cartProducts={this.props.cart.products}
                        product={product}
                        key={product.id}
                        toggleModal={this.toggleModal}
                        orderType={this.props.cart.orderType}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Breakpoint>

        <Breakpoint small down>
          <div className="product-grid">
            <div className="product-container">
              {this.state.sections.map((section, index) => (
                <div className="section-container" key={index}>
                  <Section title={section.title} id={section.id} description={section.description} />
                  {section.data.map((product) =>
                    <div key={product.id}>
                      <ProductRow
                        cartProducts={this.props.cart.products}
                        product={product}
                        key={product.id}
                        toggleModal={this.toggleModal}
                        orderType={this.props.cart.orderType}
                      />
                      <div className="product-margin" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

        </Breakpoint>


        {this.readOnly === false && this.state.canOrder && this.props.cart.products.length > 0 && (
          <button className="logo logo-basket" onClick={() => this.setState({ showCart: true })}>
            <div className="footer">
              <div className="confirm-btn">
                <span>{this.props.cart.totalProducts}</span>
                <span>Ma commande</span>
                <span>{`${this.props.cart.total.toFixed(2)} €`}</span>
              </div>
            </div>
          </button>
        )}

        <Modal
          show={this.state.showProductDetail}
          className="custom-map-modal"
          onHide={this.closeProductModal}
          size="md"
        >
          <ProductDetail
            cart={this.props.cart}
            product={this.state.selectedProduct}
            closeModal={this.closeProductModal}
            updateIndex={this.state.selectedIdx}
            options={this.options}
            readOnly={this.readOnly}
            canOrder={this.state.canOrder}
          />
        </Modal>

        <Modal
          show={this.state.showCart}
          className="custom-map-modal"
          onHide={this.closeCartModal}
          size="md"
        >
          <CartTable
            myCart={this.props.cart}
            cart={this.props.cart}
            closeModal={this.closeCartModal}
            options={this.options}
            readOnly={this.readOnly}
          />

        </Modal>

      </Container >
    )
  }
}

// redux

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    settings: state.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestoInfo: (a, b, c, d, e, f) => dispatch(updateRestoInfo(a, b, c, d, e, f)),
    updateRestoSettings: (a, b, c, d, e, f, g, h) => dispatch(updateRestoSettings(a, b, c, d, e, f, g, h)),
    updateType: (type) => dispatch(updateType(type)),
    applyDiscount: (d) => dispatch(applyDiscount(d)),
    updateOrderSchedule: (schedule) => dispatch(updateOrderSchedule(schedule)),
    updateDeliverySchedule: (schedule) => dispatch(updateDeliverySchedule(schedule)),
    updateDeliverySettings: (s) => dispatch(updateDeliverySettings(s)),
    updateTakeawaySettings: (s) => dispatch(updateTakeawaySettings(s)),
    resetCart: () => dispatch(resetCart()),
    applyCoupon: (coupon) => dispatch(applyCoupon(coupon)),
    pickedOrderType: () => dispatch(pickedOrderType()),
    updateTable: (table) => dispatch(updateTable(table)),
    updateQrId: (qrId) => dispatch(updateQrId(qrId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantTable);
